import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0fcb9b76 = () => interopDefault(import('../pages/check-email.vue' /* webpackChunkName: "pages/check-email" */))
const _0f7bd6f0 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _5d1184fb = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _ee7443b4 = () => interopDefault(import('../pages/medications/index.vue' /* webpackChunkName: "pages/medications/index" */))
const _237d5f8c = () => interopDefault(import('../pages/password-updated.vue' /* webpackChunkName: "pages/password-updated" */))
const _49fa36c5 = () => interopDefault(import('../pages/paylinks/index.vue' /* webpackChunkName: "pages/paylinks/index" */))
const _0bee5b8a = () => interopDefault(import('../pages/prescriptions/index.vue' /* webpackChunkName: "pages/prescriptions/index" */))
const _5fb75158 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _95ae851a = () => interopDefault(import('../pages/registered.vue' /* webpackChunkName: "pages/registered" */))
const _13482a66 = () => interopDefault(import('../pages/resend-confirmation.vue' /* webpackChunkName: "pages/resend-confirmation" */))
const _49d89f6c = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _55c824d4 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _56f176dd = () => interopDefault(import('../pages/settings/close-account.vue' /* webpackChunkName: "pages/settings/close-account" */))
const _12546fff = () => interopDefault(import('../pages/settings/export.vue' /* webpackChunkName: "pages/settings/export" */))
const _0c7279fe = () => interopDefault(import('../pages/settings/hospitals/index.vue' /* webpackChunkName: "pages/settings/hospitals/index" */))
const _b938e63a = () => interopDefault(import('../pages/settings/options.vue' /* webpackChunkName: "pages/settings/options" */))
const _09b96e48 = () => interopDefault(import('../pages/settings/pharmacies.vue' /* webpackChunkName: "pages/settings/pharmacies" */))
const _712f0ccb = () => interopDefault(import('../pages/settings/security.vue' /* webpackChunkName: "pages/settings/security" */))
const _af349554 = () => interopDefault(import('../pages/settings/user.vue' /* webpackChunkName: "pages/settings/user" */))
const _e39d32e2 = () => interopDefault(import('../pages/settings/hospitals/new.vue' /* webpackChunkName: "pages/settings/hospitals/new" */))
const _4cd71528 = () => interopDefault(import('../pages/settings/hospitals/_id/index.vue' /* webpackChunkName: "pages/settings/hospitals/_id/index" */))
const _bc655f40 = () => interopDefault(import('../pages/settings/hospitals/_id/edit.vue' /* webpackChunkName: "pages/settings/hospitals/_id/edit" */))
const _89f0ed26 = () => interopDefault(import('../pages/embed/rx.vue' /* webpackChunkName: "pages/embed/rx" */))
const _5f7c076e = () => interopDefault(import('../pages/prescriptions/new.vue' /* webpackChunkName: "pages/prescriptions/new" */))
const _6c7b301e = () => interopDefault(import('../pages/redirect/new.vue' /* webpackChunkName: "pages/redirect/new" */))
const _2e9a7171 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4843957d = () => interopDefault(import('../pages/paylinks/_slug.vue' /* webpackChunkName: "pages/paylinks/_slug" */))
const _3a558c20 = () => interopDefault(import('../pages/prescriptions/_signature.vue' /* webpackChunkName: "pages/prescriptions/_signature" */))
const _31f56d82 = () => interopDefault(import('../pages/new-password/_uid/_token.vue' /* webpackChunkName: "pages/new-password/_uid/_token" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/check-email",
    component: _0fcb9b76,
    name: "check-email"
  }, {
    path: "/login",
    component: _0f7bd6f0,
    name: "login"
  }, {
    path: "/logout",
    component: _5d1184fb,
    name: "logout"
  }, {
    path: "/medications",
    component: _ee7443b4,
    name: "medications"
  }, {
    path: "/password-updated",
    component: _237d5f8c,
    name: "password-updated"
  }, {
    path: "/paylinks",
    component: _49fa36c5,
    name: "paylinks"
  }, {
    path: "/prescriptions",
    component: _0bee5b8a,
    name: "prescriptions"
  }, {
    path: "/register",
    component: _5fb75158,
    name: "register"
  }, {
    path: "/registered",
    component: _95ae851a,
    name: "registered"
  }, {
    path: "/resend-confirmation",
    component: _13482a66,
    name: "resend-confirmation"
  }, {
    path: "/reset-password",
    component: _49d89f6c,
    name: "reset-password"
  }, {
    path: "/settings",
    component: _55c824d4,
    name: "settings",
    children: [{
      path: "close-account",
      component: _56f176dd,
      name: "settings-close-account"
    }, {
      path: "export",
      component: _12546fff,
      name: "settings-export"
    }, {
      path: "hospitals",
      component: _0c7279fe,
      name: "settings-hospitals"
    }, {
      path: "options",
      component: _b938e63a,
      name: "settings-options"
    }, {
      path: "pharmacies",
      component: _09b96e48,
      name: "settings-pharmacies"
    }, {
      path: "security",
      component: _712f0ccb,
      name: "settings-security"
    }, {
      path: "user",
      component: _af349554,
      name: "settings-user"
    }, {
      path: "hospitals/new",
      component: _e39d32e2,
      name: "settings-hospitals-new"
    }, {
      path: "hospitals/:id",
      component: _4cd71528,
      name: "settings-hospitals-id"
    }, {
      path: "hospitals/:id/edit",
      component: _bc655f40,
      name: "settings-hospitals-id-edit"
    }]
  }, {
    path: "/embed/rx",
    component: _89f0ed26,
    name: "embed-rx"
  }, {
    path: "/prescriptions/new",
    component: _5f7c076e,
    name: "prescriptions-new"
  }, {
    path: "/redirect/new",
    component: _6c7b301e,
    name: "redirect-new"
  }, {
    path: "/",
    component: _2e9a7171,
    name: "index"
  }, {
    path: "/paylinks/:slug",
    component: _4843957d,
    name: "paylinks-slug"
  }, {
    path: "/prescriptions/:signature",
    component: _3a558c20,
    name: "prescriptions-signature"
  }, {
    path: "/new-password/:uid?/:token?",
    component: _31f56d82,
    name: "new-password-uid-token"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
